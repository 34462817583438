<template>
  <div>
    <b-modal
      id="MessageTemplateEdit"
      title="Изменить шаблон"
      size="sm"
      centered
      hide-footer
      no-enforce-focus
      :no-close-on-backdrop="true"
      @close="close"
      @show="getTemplate"
    >
      <b-row class="mb-1">
        <b-col cols="12">
          <label for="">Название шаблона <i class="required">*</i></label>
          <b-input
            v-model="messageTemplate.title"
            placeholder="Название шаблона"
          />
        </b-col>
      </b-row>
      <b-row class="mb-1">
        <b-col cols="12">
          <label for="">Тип шаблона <i class="required">*</i></label>
          <v-select
            v-model="messageTemplate.type"
            :reduce="(option) => option.name"
            placeholder="Тип шаблона"
            label="name"
            :options="types"
          />
        </b-col>
      </b-row>
      <b-row class="mb-1">
        <b-col cols="12">
          <label for="">Описание шаблона <i class="required">*</i></label>
          <b-textarea
            v-model="messageTemplate.description"
            placeholder="Описание шаблона"
          ></b-textarea>
        </b-col>
      </b-row>
      <b-row class="mb-1 mt-2">
        <b-col class="d-flex justify-content-between">
          <b-button @click="close()" variant="danger">Отмена</b-button>
          <b-button
            @click="edit()"
            :disabled="submitButtonDisabled"
            variant="primary"
            >Изменить</b-button
          >
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    ToastificationContent,
    vSelect,
  },
  props: ["id", "types"],
  data() {
    return {
      messageTemplate: {
        title: null,
        description: null,
        type: null,
      },
      submitButtonDisabled: false,
    };
  },
  methods: {
    close() {
      this.$bvModal.hide("MessageTemplateEdit");
      this.clearData();
    },
    clearData() {
      this.messageTemplate = {
        title: null,
        description: null,
        type: null,
      };
    },
    getTemplate() {
      this.$http
        .get(`message-templates/${this.id}`)
        .then((res) => {
          this.messageTemplate = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    edit() {
      this.submitButtonDisabled = true;
      this.$http
        .put(
          `message-templates/${this.messageTemplate.id}`,
          this.messageTemplate
        )
        .then((res) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Шаблон изменен!",
              icon: "XIcon",
              variant: "success",
              text: res.data.message,
            },
          });
          this.close();
          this.$emit("refresh");
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Некорректные данные!",
              icon: "XIcon",
              variant: "danger",
              text: error.response.data.errors,
            },
          });
        })
        .finally(() => {
          this.submitButtonDisabled = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.required {
  color: red;
}
</style>
